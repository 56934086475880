import React from 'react';

function BankPayment() {
    const bankData = [
        {
            id: 1,
            bank_name: 'BRAC Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'CD# 1501202597996001',
        },
        {
            id: 2,
            bank_name: 'BRAC Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 1505102597996001',
        },
        {
            id: 3,
            bank_name: 'BRAC Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 1505102597996002',
        },
        {
            id: 4,
            bank_name: 'Mutual Trust Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 0020320003178',
        },
        {
            id: 5,
            bank_name: 'IFIC Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 1094010297041',
        },
        {
            id: 6,
            bank_name: 'AB Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'CD# 4005-787623-000',
        },
        {
            id: 7,
            bank_name: 'Dutch-Bangla Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 255.120.377',
        },
        {
            id: 8,
            bank_name: 'Bank Asia Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 05636000109',
        },
        {
            id: 9,
            bank_name: 'Community Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 0010302038301',
        },
        {
            id: 10,
            bank_name: 'Midland Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 0011-1090001015',
        },

        {
            id: 11,
            bank_name: 'SBAC Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 0010130000441',
        },
        {
            id: 12,
            bank_name: 'Prime Bank',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND#2118313025754',
        },
        {
            id: 13,
            bank_name: 'Meghna Bank',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 1101 13500000134',
        },
        {
            id: 14,
            bank_name: 'Uttara Bank Ltd',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND# 0177 14100000011',
        },
        {
            id: 15,
            bank_name: 'Pubali Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'SND # 4709 102000330',
        },
        {
            id: 16,
            bank_name: 'Mutual Trust Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_no: 'Yaqeen SND # 9991520000507',
        },
    ];

    const bankData1 = [
        {
            id: 1,
            bank_name: 'BRAC Bank Limited.',
            account_name: 'Guardian Life Insurance Limited',
            ac_type: 'CD',
            ac_no: '1501202597996001',
            routing_no: '060261726',
            branch_name: 'Gulshan Branch',
        },
        {
            id: 2,
            bank_name: 'Dutch-Bangla Bank Limited',
            account_name: 'Guardian Life Insurance Limited',
            ac_type: 'CD',
            ac_no: '2551200000377',
            routing_no: '090270424',
            branch_name: '255-PANTHAPATH BRANCH',
        },
        {
            id: 3,
            bank_name:
                'IFIC (International Finance Invest and Commerce Bank Limited)',
            account_name: 'Guardian Life Insurance Limited',
            ac_type: 'CURRENT ACCOUNT',
            ac_no: '1094010297041',
            routing_no: '120263198',
            branch_name: 'Mohakhali Branch',
        },
        {
            id: 4,
            bank_name: 'Mutual Trust Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_type: 'CURRENT ACCOUNT',
            ac_no: '0020320003178',
            routing_no: '145275358',
            branch_name: 'Principal Branch',
        },
        {
            id: 5,
            bank_name: 'Uttara Bank Ltd',
            account_name: 'Guardian Life Insurance Limited',
            ac_type: 'SND',
            ac_no: ' 017714100000011',
            routing_no: '250260908',
            branch_name: 'Circle-1 Branch,Gulshan',
        },
        {
            id: 6,
            bank_name: 'Pubali Bank Ltd.',
            account_name: 'Guardian Life Insurance Limited',
            ac_type: 'SND',
            ac_no: '4709102000330',
            routing_no: '175261758',
            branch_name: 'Gulshan Circle-1 Branch',
        },
        {
            id: 7,
            bank_name: 'Islami Bank Bangladesh Limited.',
            account_name: 'Guardian Life Insurance Limited. (Takaful)',
            ac_type: 'MSND(Regular)',
            ac_no: '20502760900011012',
            routing_no: '125261753',
            branch_name: 'Gulshan Circle 1 Branch',
        },

    ];

    return (
        <div className="body-container secondary-container">
            <div className="py-5">
                <div className="mt-3 table-responsive">
                    <table className="custom-table w-100">
                        <thead>
                            <tr>
                                <th className="payment-title fw-bold">
                                    SL No.
                                </th>
                                <th className="payment-title  fw-bold">
                                    Name of Bank
                                </th>
                                <th className="payment-title  fw-bold">
                                    Account Name
                                </th>
                                <th className="payment-title  fw-bold">
                                    A/C Type
                                </th>
                                <th className="payment-title  fw-bold">
                                    A/C No
                                </th>
                                <th className="payment-title  fw-bold">
                                    Routing No.
                                </th>
                                <th className="payment-title  fw-bold">
                                    Branch Name
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {bankData1.map((data) => (
                                <tr key={data?.id}>
                                    <td>{data.id}</td>
                                    <td>{data.bank_name} </td>
                                    <td>{data.account_name}</td>
                                    <td>{data.ac_type}</td>
                                    <td>{data.ac_no}</td>
                                    <td>{data.routing_no}</td>
                                    <td>{data.branch_name}</td>
                                </tr>
                            ))}
                            <tr></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default BankPayment;
